export default function (req, res, next) {
  const redirects = {
    "/audio-converter": [
      "/m4p-converter",
      "/m4p-to-3gp",
      "/m4p-to-aac",
      "/m4p-to-aiff",
      "/m4p-to-alac",
      "/m4p-to-amr",
      "/m4p-to-android-audio",
      "/m4p-to-avi",
      "/m4p-to-flac",
      "/m4p-to-flv",
      "/m4p-to-ipad-audio",
      "/m4p-to-iphone-audio",
      "/m4p-to-ipod-audio",
      "/m4p-to-m4a",
      "/m4p-to-mkv",
      "/m4p-to-mov",
      "/m4p-to-mp3",
      "/m4p-to-mp4",
      "/m4p-to-ogg",
      "/m4p-to-ogv",
      "/m4p-to-wav",
      "/m4p-to-webm",
      "/m4p-to-wma",
      "/m4p-to-wmv",
      "/en/m4p-converter",
      "/en/m4p-to-3gp",
      "/en/m4p-to-aac",
      "/en/m4p-to-aiff",
      "/en/m4p-to-alac",
      "/en/m4p-to-amr",
      "/en/m4p-to-android-audio",
      "/en/m4p-to-avi",
      "/en/m4p-to-flac",
      "/en/m4p-to-flv",
      "/en/m4p-to-ipad-audio",
      "/en/m4p-to-iphone-audio",
      "/en/m4p-to-ipod-audio",
      "/en/m4p-to-m4a",
      "/en/m4p-to-mkv",
      "/en/m4p-to-mov",
      "/en/m4p-to-mp3",
      "/en/m4p-to-mp4",
      "/en/m4p-to-ogg",
      "/en/m4p-to-ogv",
      "/en/m4p-to-wav",
      "/en/m4p-to-webm",
      "/en/m4p-to-wma",
      "/en/m4p-to-wmv",
    ],
    "/de/audio-converter": [
      "/de/m4p-converter",
      "/de/m4p-to-3gp",
      "/de/m4p-to-aac",
      "/de/m4p-to-aiff",
      "/de/m4p-to-alac",
      "/de/m4p-to-amr",
      "/de/m4p-to-android-audio",
      "/de/m4p-to-avi",
      "/de/m4p-to-flac",
      "/de/m4p-to-flv",
      "/de/m4p-to-ipad-audio",
      "/de/m4p-to-iphone-audio",
      "/de/m4p-to-ipod-audio",
      "/de/m4p-to-m4a",
      "/de/m4p-to-mkv",
      "/de/m4p-to-mov",
      "/de/m4p-to-mp3",
      "/de/m4p-to-mp4",
      "/de/m4p-to-ogg",
      "/de/m4p-to-ogv",
      "/de/m4p-to-wav",
      "/de/m4p-to-webm",
      "/de/m4p-to-wma",
      "/de/m4p-to-wmv",
    ],
    "/es/audio-converter": [
      "/es/m4p-converter",
      "/es/m4p-to-3gp",
      "/es/m4p-to-aac",
      "/es/m4p-to-aiff",
      "/es/m4p-to-alac",
      "/es/m4p-to-amr",
      "/es/m4p-to-android-audio",
      "/es/m4p-to-avi",
      "/es/m4p-to-flac",
      "/es/m4p-to-flv",
      "/es/m4p-to-ipad-audio",
      "/es/m4p-to-iphone-audio",
      "/es/m4p-to-ipod-audio",
      "/es/m4p-to-m4a",
      "/es/m4p-to-mkv",
      "/es/m4p-to-mov",
      "/es/m4p-to-mp3",
      "/es/m4p-to-mp4",
      "/es/m4p-to-ogg",
      "/es/m4p-to-ogv",
      "/es/m4p-to-wav",
      "/es/m4p-to-webm",
      "/es/m4p-to-wma",
      "/es/m4p-to-wmv",
    ],
    "/fr/audio-converter": [
      "/fr/m4p-converter",
      "/fr/m4p-to-3gp",
      "/fr/m4p-to-aac",
      "/fr/m4p-to-aiff",
      "/fr/m4p-to-alac",
      "/fr/m4p-to-amr",
      "/fr/m4p-to-android-audio",
      "/fr/m4p-to-avi",
      "/fr/m4p-to-flac",
      "/fr/m4p-to-flv",
      "/fr/m4p-to-ipad-audio",
      "/fr/m4p-to-iphone-audio",
      "/fr/m4p-to-ipod-audio",
      "/fr/m4p-to-m4a",
      "/fr/m4p-to-mkv",
      "/fr/m4p-to-mov",
      "/fr/m4p-to-mp3",
      "/fr/m4p-to-mp4",
      "/fr/m4p-to-ogg",
      "/fr/m4p-to-ogv",
      "/fr/m4p-to-wav",
      "/fr/m4p-to-webm",
      "/fr/m4p-to-wma",
      "/fr/m4p-to-wmv",
    ],
    "/id/audio-converter": [
      "/id/m4p-converter",
      "/id/m4p-to-3gp",
      "/id/m4p-to-aac",
      "/id/m4p-to-aiff",
      "/id/m4p-to-alac",
      "/id/m4p-to-amr",
      "/id/m4p-to-android-audio",
      "/id/m4p-to-avi",
      "/id/m4p-to-flac",
      "/id/m4p-to-flv",
      "/id/m4p-to-ipad-audio",
      "/id/m4p-to-iphone-audio",
      "/id/m4p-to-ipod-audio",
      "/id/m4p-to-m4a",
      "/id/m4p-to-mkv",
      "/id/m4p-to-mov",
      "/id/m4p-to-mp3",
      "/id/m4p-to-mp4",
      "/id/m4p-to-ogg",
      "/id/m4p-to-ogv",
      "/id/m4p-to-wav",
      "/id/m4p-to-webm",
      "/id/m4p-to-wma",
      "/id/m4p-to-wmv",
    ],
    "/it/audio-converter": [
      "/it/m4p-converter",
      "/it/m4p-to-3gp",
      "/it/m4p-to-aac",
      "/it/m4p-to-aiff",
      "/it/m4p-to-alac",
      "/it/m4p-to-amr",
      "/it/m4p-to-android-audio",
      "/it/m4p-to-avi",
      "/it/m4p-to-flac",
      "/it/m4p-to-flv",
      "/it/m4p-to-ipad-audio",
      "/it/m4p-to-iphone-audio",
      "/it/m4p-to-ipod-audio",
      "/it/m4p-to-m4a",
      "/it/m4p-to-mkv",
      "/it/m4p-to-mov",
      "/it/m4p-to-mp3",
      "/it/m4p-to-mp4",
      "/it/m4p-to-ogg",
      "/it/m4p-to-ogv",
      "/it/m4p-to-wav",
      "/it/m4p-to-webm",
      "/it/m4p-to-wma",
      "/it/m4p-to-wmv",
    ],
    "/ja/audio-converter": [
      "/ja/m4p-converter",
      "/ja/m4p-to-3gp",
      "/ja/m4p-to-aac",
      "/ja/m4p-to-aiff",
      "/ja/m4p-to-alac",
      "/ja/m4p-to-amr",
      "/ja/m4p-to-android-audio",
      "/ja/m4p-to-avi",
      "/ja/m4p-to-flac",
      "/ja/m4p-to-flv",
      "/ja/m4p-to-ipad-audio",
      "/ja/m4p-to-iphone-audio",
      "/ja/m4p-to-ipod-audio",
      "/ja/m4p-to-m4a",
      "/ja/m4p-to-mkv",
      "/ja/m4p-to-mov",
      "/ja/m4p-to-mp3",
      "/ja/m4p-to-mp4",
      "/ja/m4p-to-ogg",
      "/ja/m4p-to-ogv",
      "/ja/m4p-to-wav",
      "/ja/m4p-to-webm",
      "/ja/m4p-to-wma",
      "/ja/m4p-to-wmv",
    ],
    "/ko/audio-converter": [
      "/ko/m4p-converter",
      "/ko/m4p-to-3gp",
      "/ko/m4p-to-aac",
      "/ko/m4p-to-aiff",
      "/ko/m4p-to-alac",
      "/ko/m4p-to-amr",
      "/ko/m4p-to-android-audio",
      "/ko/m4p-to-avi",
      "/ko/m4p-to-flac",
      "/ko/m4p-to-flv",
      "/ko/m4p-to-ipad-audio",
      "/ko/m4p-to-iphone-audio",
      "/ko/m4p-to-ipod-audio",
      "/ko/m4p-to-m4a",
      "/ko/m4p-to-mkv",
      "/ko/m4p-to-mov",
      "/ko/m4p-to-mp3",
      "/ko/m4p-to-mp4",
      "/ko/m4p-to-ogg",
      "/ko/m4p-to-ogv",
      "/ko/m4p-to-wav",
      "/ko/m4p-to-webm",
      "/ko/m4p-to-wma",
      "/ko/m4p-to-wmv",
    ],
    "/nl/audio-converter": [
      "/nl/m4p-converter",
      "/nl/m4p-to-3gp",
      "/nl/m4p-to-aac",
      "/nl/m4p-to-aiff",
      "/nl/m4p-to-alac",
      "/nl/m4p-to-amr",
      "/nl/m4p-to-android-audio",
      "/nl/m4p-to-avi",
      "/nl/m4p-to-flac",
      "/nl/m4p-to-flv",
      "/nl/m4p-to-ipad-audio",
      "/nl/m4p-to-iphone-audio",
      "/nl/m4p-to-ipod-audio",
      "/nl/m4p-to-m4a",
      "/nl/m4p-to-mkv",
      "/nl/m4p-to-mov",
      "/nl/m4p-to-mp3",
      "/nl/m4p-to-mp4",
      "/nl/m4p-to-ogg",
      "/nl/m4p-to-ogv",
      "/nl/m4p-to-wav",
      "/nl/m4p-to-webm",
      "/nl/m4p-to-wma",
      "/nl/m4p-to-wmv",
    ],
    "/pl/audio-converter": [
      "/pl/m4p-converter",
      "/pl/m4p-to-3gp",
      "/pl/m4p-to-aac",
      "/pl/m4p-to-aiff",
      "/pl/m4p-to-alac",
      "/pl/m4p-to-amr",
      "/pl/m4p-to-android-audio",
      "/pl/m4p-to-avi",
      "/pl/m4p-to-flac",
      "/pl/m4p-to-flv",
      "/pl/m4p-to-ipad-audio",
      "/pl/m4p-to-iphone-audio",
      "/pl/m4p-to-ipod-audio",
      "/pl/m4p-to-m4a",
      "/pl/m4p-to-mkv",
      "/pl/m4p-to-mov",
      "/pl/m4p-to-mp3",
      "/pl/m4p-to-mp4",
      "/pl/m4p-to-ogg",
      "/pl/m4p-to-ogv",
      "/pl/m4p-to-wav",
      "/pl/m4p-to-webm",
      "/pl/m4p-to-wma",
      "/pl/m4p-to-wmv",
    ],
    "/pt/audio-converter": [
      "/pt/m4p-converter",
      "/pt/m4p-to-3gp",
      "/pt/m4p-to-aac",
      "/pt/m4p-to-aiff",
      "/pt/m4p-to-alac",
      "/pt/m4p-to-amr",
      "/pt/m4p-to-android-audio",
      "/pt/m4p-to-avi",
      "/pt/m4p-to-flac",
      "/pt/m4p-to-flv",
      "/pt/m4p-to-ipad-audio",
      "/pt/m4p-to-iphone-audio",
      "/pt/m4p-to-ipod-audio",
      "/pt/m4p-to-m4a",
      "/pt/m4p-to-mkv",
      "/pt/m4p-to-mov",
      "/pt/m4p-to-mp3",
      "/pt/m4p-to-mp4",
      "/pt/m4p-to-ogg",
      "/pt/m4p-to-ogv",
      "/pt/m4p-to-wav",
      "/pt/m4p-to-webm",
      "/pt/m4p-to-wma",
      "/pt/m4p-to-wmv",
    ],
    "/sv/audio-converter": [
      "/sv/m4p-converter",
      "/sv/m4p-to-3gp",
      "/sv/m4p-to-aac",
      "/sv/m4p-to-aiff",
      "/sv/m4p-to-alac",
      "/sv/m4p-to-amr",
      "/sv/m4p-to-android-audio",
      "/sv/m4p-to-avi",
      "/sv/m4p-to-flac",
      "/sv/m4p-to-flv",
      "/sv/m4p-to-ipad-audio",
      "/sv/m4p-to-iphone-audio",
      "/sv/m4p-to-ipod-audio",
      "/sv/m4p-to-m4a",
      "/sv/m4p-to-mkv",
      "/sv/m4p-to-mov",
      "/sv/m4p-to-mp3",
      "/sv/m4p-to-mp4",
      "/sv/m4p-to-ogg",
      "/sv/m4p-to-ogv",
      "/sv/m4p-to-wav",
      "/sv/m4p-to-webm",
      "/sv/m4p-to-wma",
      "/sv/m4p-to-wmv",
    ],
    "/tr/audio-converter": [
      "/tr/m4p-converter",
      "/tr/m4p-to-3gp",
      "/tr/m4p-to-aac",
      "/tr/m4p-to-aiff",
      "/tr/m4p-to-alac",
      "/tr/m4p-to-amr",
      "/tr/m4p-to-android-audio",
      "/tr/m4p-to-avi",
      "/tr/m4p-to-flac",
      "/tr/m4p-to-flv",
      "/tr/m4p-to-ipad-audio",
      "/tr/m4p-to-iphone-audio",
      "/tr/m4p-to-ipod-audio",
      "/tr/m4p-to-m4a",
      "/tr/m4p-to-mkv",
      "/tr/m4p-to-mov",
      "/tr/m4p-to-mp3",
      "/tr/m4p-to-mp4",
      "/tr/m4p-to-ogg",
      "/tr/m4p-to-ogv",
      "/tr/m4p-to-wav",
      "/tr/m4p-to-webm",
      "/tr/m4p-to-wma",
      "/tr/m4p-to-wmv",
    ],
    "/zh/audio-converter": [
      "/zh/m4p-converter",
      "/zh/m4p-to-3gp",
      "/zh/m4p-to-aac",
      "/zh/m4p-to-aiff",
      "/zh/m4p-to-alac",
      "/zh/m4p-to-amr",
      "/zh/m4p-to-android-audio",
      "/zh/m4p-to-avi",
      "/zh/m4p-to-flac",
      "/zh/m4p-to-flv",
      "/zh/m4p-to-ipad-audio",
      "/zh/m4p-to-iphone-audio",
      "/zh/m4p-to-ipod-audio",
      "/zh/m4p-to-m4a",
      "/zh/m4p-to-mkv",
      "/zh/m4p-to-mov",
      "/zh/m4p-to-mp3",
      "/zh/m4p-to-mp4",
      "/zh/m4p-to-ogg",
      "/zh/m4p-to-ogv",
      "/zh/m4p-to-wav",
      "/zh/m4p-to-webm",
      "/zh/m4p-to-wma",
      "/zh/m4p-to-wmv",
    ],
    "/video-converter": [
      "/m4v-converter",
      "/m4v-to-3gp",
      "/m4v-to-aac",
      "/m4v-to-aiff",
      "/m4v-to-alac",
      "/m4v-to-amr",
      "/m4v-to-android-video",
      "/m4v-to-avi",
      "/m4v-to-flac",
      "/m4v-to-flv",
      "/m4v-to-ipad-video",
      "/m4v-to-iphone-video",
      "/m4v-to-kindle-video",
      "/m4v-to-m4a",
      "/m4v-to-mkv",
      "/m4v-to-mobile-video",
      "/m4v-to-mov",
      "/m4v-to-mp3",
      "/m4v-to-mp4",
      "/m4v-to-ogg",
      "/m4v-to-ogv",
      "/m4v-to-psp-video",
      "/m4v-to-wav",
      "/m4v-to-webm",
      "/m4v-to-wma",
      "/m4v-to-wmv",
      "/m4v-to-xbox-video",
      "/en/m4v-converter",
      "/en/m4v-to-3gp",
      "/en/m4v-to-aac",
      "/en/m4v-to-aiff",
      "/en/m4v-to-alac",
      "/en/m4v-to-amr",
      "/en/m4v-to-android-video",
      "/en/m4v-to-avi",
      "/en/m4v-to-flac",
      "/en/m4v-to-flv",
      "/en/m4v-to-ipad-video",
      "/en/m4v-to-iphone-video",
      "/en/m4v-to-kindle-video",
      "/en/m4v-to-m4a",
      "/en/m4v-to-mkv",
      "/en/m4v-to-mobile-video",
      "/en/m4v-to-mov",
      "/en/m4v-to-mp3",
      "/en/m4v-to-mp4",
      "/en/m4v-to-ogg",
      "/en/m4v-to-ogv",
      "/en/m4v-to-psp-video",
      "/en/m4v-to-wav",
      "/en/m4v-to-webm",
      "/en/m4v-to-wma",
      "/en/m4v-to-wmv",
      "/en/m4v-to-xbox-video",
    ],
    "/de/video-converter": [
      "/de/m4v-converter",
      "/de/m4v-to-3gp",
      "/de/m4v-to-aac",
      "/de/m4v-to-aiff",
      "/de/m4v-to-alac",
      "/de/m4v-to-amr",
      "/de/m4v-to-android-video",
      "/de/m4v-to-avi",
      "/de/m4v-to-flac",
      "/de/m4v-to-flv",
      "/de/m4v-to-ipad-video",
      "/de/m4v-to-iphone-video",
      "/de/m4v-to-kindle-video",
      "/de/m4v-to-m4a",
      "/de/m4v-to-mkv",
      "/de/m4v-to-mobile-video",
      "/de/m4v-to-mov",
      "/de/m4v-to-mp3",
      "/de/m4v-to-mp4",
      "/de/m4v-to-ogg",
      "/de/m4v-to-ogv",
      "/de/m4v-to-psp-video",
      "/de/m4v-to-wav",
      "/de/m4v-to-webm",
      "/de/m4v-to-wma",
      "/de/m4v-to-wmv",
      "/de/m4v-to-xbox-video",
    ],
    "/es/video-converter": [
      "/es/m4v-converter",
      "/es/m4v-to-3gp",
      "/es/m4v-to-aac",
      "/es/m4v-to-aiff",
      "/es/m4v-to-alac",
      "/es/m4v-to-amr",
      "/es/m4v-to-android-video",
      "/es/m4v-to-avi",
      "/es/m4v-to-flac",
      "/es/m4v-to-flv",
      "/es/m4v-to-ipad-video",
      "/es/m4v-to-iphone-video",
      "/es/m4v-to-kindle-video",
      "/es/m4v-to-m4a",
      "/es/m4v-to-mkv",
      "/es/m4v-to-mobile-video",
      "/es/m4v-to-mov",
      "/es/m4v-to-mp3",
      "/es/m4v-to-mp4",
      "/es/m4v-to-ogg",
      "/es/m4v-to-ogv",
      "/es/m4v-to-psp-video",
      "/es/m4v-to-wav",
      "/es/m4v-to-webm",
      "/es/m4v-to-wma",
      "/es/m4v-to-wmv",
      "/es/m4v-to-xbox-video",
    ],
    "/fr/video-converter": [
      "/fr/m4v-converter",
      "/fr/m4v-to-3gp",
      "/fr/m4v-to-aac",
      "/fr/m4v-to-aiff",
      "/fr/m4v-to-alac",
      "/fr/m4v-to-amr",
      "/fr/m4v-to-android-video",
      "/fr/m4v-to-avi",
      "/fr/m4v-to-flac",
      "/fr/m4v-to-flv",
      "/fr/m4v-to-ipad-video",
      "/fr/m4v-to-iphone-video",
      "/fr/m4v-to-kindle-video",
      "/fr/m4v-to-m4a",
      "/fr/m4v-to-mkv",
      "/fr/m4v-to-mobile-video",
      "/fr/m4v-to-mov",
      "/fr/m4v-to-mp3",
      "/fr/m4v-to-mp4",
      "/fr/m4v-to-ogg",
      "/fr/m4v-to-ogv",
      "/fr/m4v-to-psp-video",
      "/fr/m4v-to-wav",
      "/fr/m4v-to-webm",
      "/fr/m4v-to-wma",
      "/fr/m4v-to-wmv",
      "/fr/m4v-to-xbox-video",
    ],
    "/id/video-converter": [
      "/id/m4v-converter",
      "/id/m4v-to-3gp",
      "/id/m4v-to-aac",
      "/id/m4v-to-aiff",
      "/id/m4v-to-alac",
      "/id/m4v-to-amr",
      "/id/m4v-to-android-video",
      "/id/m4v-to-avi",
      "/id/m4v-to-flac",
      "/id/m4v-to-flv",
      "/id/m4v-to-ipad-video",
      "/id/m4v-to-iphone-video",
      "/id/m4v-to-kindle-video",
      "/id/m4v-to-m4a",
      "/id/m4v-to-mkv",
      "/id/m4v-to-mobile-video",
      "/id/m4v-to-mov",
      "/id/m4v-to-mp3",
      "/id/m4v-to-mp4",
      "/id/m4v-to-ogg",
      "/id/m4v-to-ogv",
      "/id/m4v-to-psp-video",
      "/id/m4v-to-wav",
      "/id/m4v-to-webm",
      "/id/m4v-to-wma",
      "/id/m4v-to-wmv",
      "/id/m4v-to-xbox-video",
    ],
    "/it/video-converter": [
      "/it/m4v-converter",
      "/it/m4v-to-3gp",
      "/it/m4v-to-aac",
      "/it/m4v-to-aiff",
      "/it/m4v-to-alac",
      "/it/m4v-to-amr",
      "/it/m4v-to-android-video",
      "/it/m4v-to-avi",
      "/it/m4v-to-flac",
      "/it/m4v-to-flv",
      "/it/m4v-to-ipad-video",
      "/it/m4v-to-iphone-video",
      "/it/m4v-to-kindle-video",
      "/it/m4v-to-m4a",
      "/it/m4v-to-mkv",
      "/it/m4v-to-mobile-video",
      "/it/m4v-to-mov",
      "/it/m4v-to-mp3",
      "/it/m4v-to-mp4",
      "/it/m4v-to-ogg",
      "/it/m4v-to-ogv",
      "/it/m4v-to-psp-video",
      "/it/m4v-to-wav",
      "/it/m4v-to-webm",
      "/it/m4v-to-wma",
      "/it/m4v-to-wmv",
      "/it/m4v-to-xbox-video",
    ],
    "/ja/video-converter": [
      "/ja/m4v-converter",
      "/ja/m4v-to-3gp",
      "/ja/m4v-to-aac",
      "/ja/m4v-to-aiff",
      "/ja/m4v-to-alac",
      "/ja/m4v-to-amr",
      "/ja/m4v-to-android-video",
      "/ja/m4v-to-avi",
      "/ja/m4v-to-flac",
      "/ja/m4v-to-flv",
      "/ja/m4v-to-ipad-video",
      "/ja/m4v-to-iphone-video",
      "/ja/m4v-to-kindle-video",
      "/ja/m4v-to-m4a",
      "/ja/m4v-to-mkv",
      "/ja/m4v-to-mobile-video",
      "/ja/m4v-to-mov",
      "/ja/m4v-to-mp3",
      "/ja/m4v-to-mp4",
      "/ja/m4v-to-ogg",
      "/ja/m4v-to-ogv",
      "/ja/m4v-to-psp-video",
      "/ja/m4v-to-wav",
      "/ja/m4v-to-webm",
      "/ja/m4v-to-wma",
      "/ja/m4v-to-wmv",
      "/ja/m4v-to-xbox-video",
    ],
    "/ko/video-converter": [
      "/ko/m4v-converter",
      "/ko/m4v-to-3gp",
      "/ko/m4v-to-aac",
      "/ko/m4v-to-aiff",
      "/ko/m4v-to-alac",
      "/ko/m4v-to-amr",
      "/ko/m4v-to-android-video",
      "/ko/m4v-to-avi",
      "/ko/m4v-to-flac",
      "/ko/m4v-to-flv",
      "/ko/m4v-to-ipad-video",
      "/ko/m4v-to-iphone-video",
      "/ko/m4v-to-kindle-video",
      "/ko/m4v-to-m4a",
      "/ko/m4v-to-mkv",
      "/ko/m4v-to-mobile-video",
      "/ko/m4v-to-mov",
      "/ko/m4v-to-mp3",
      "/ko/m4v-to-mp4",
      "/ko/m4v-to-ogg",
      "/ko/m4v-to-ogv",
      "/ko/m4v-to-psp-video",
      "/ko/m4v-to-wav",
      "/ko/m4v-to-webm",
      "/ko/m4v-to-wma",
      "/ko/m4v-to-wmv",
      "/ko/m4v-to-xbox-video",
    ],
    "/nl/video-converter": [
      "/nl/m4v-converter",
      "/nl/m4v-to-3gp",
      "/nl/m4v-to-aac",
      "/nl/m4v-to-aiff",
      "/nl/m4v-to-alac",
      "/nl/m4v-to-amr",
      "/nl/m4v-to-android-video",
      "/nl/m4v-to-avi",
      "/nl/m4v-to-flac",
      "/nl/m4v-to-flv",
      "/nl/m4v-to-ipad-video",
      "/nl/m4v-to-iphone-video",
      "/nl/m4v-to-kindle-video",
      "/nl/m4v-to-m4a",
      "/nl/m4v-to-mkv",
      "/nl/m4v-to-mobile-video",
      "/nl/m4v-to-mov",
      "/nl/m4v-to-mp3",
      "/nl/m4v-to-mp4",
      "/nl/m4v-to-ogg",
      "/nl/m4v-to-ogv",
      "/nl/m4v-to-psp-video",
      "/nl/m4v-to-wav",
      "/nl/m4v-to-webm",
      "/nl/m4v-to-wma",
      "/nl/m4v-to-wmv",
      "/nl/m4v-to-xbox-video",
    ],
    "/pl/video-converter": [
      "/pl/m4v-converter",
      "/pl/m4v-to-3gp",
      "/pl/m4v-to-aac",
      "/pl/m4v-to-aiff",
      "/pl/m4v-to-alac",
      "/pl/m4v-to-amr",
      "/pl/m4v-to-android-video",
      "/pl/m4v-to-avi",
      "/pl/m4v-to-flac",
      "/pl/m4v-to-flv",
      "/pl/m4v-to-ipad-video",
      "/pl/m4v-to-iphone-video",
      "/pl/m4v-to-kindle-video",
      "/pl/m4v-to-m4a",
      "/pl/m4v-to-mkv",
      "/pl/m4v-to-mobile-video",
      "/pl/m4v-to-mov",
      "/pl/m4v-to-mp3",
      "/pl/m4v-to-mp4",
      "/pl/m4v-to-ogg",
      "/pl/m4v-to-ogv",
      "/pl/m4v-to-psp-video",
      "/pl/m4v-to-wav",
      "/pl/m4v-to-webm",
      "/pl/m4v-to-wma",
      "/pl/m4v-to-wmv",
      "/pl/m4v-to-xbox-video",
    ],
    "/sv/video-converter": [
      "/sv/m4v-converter",
      "/sv/m4v-to-3gp",
      "/sv/m4v-to-aac",
      "/sv/m4v-to-aiff",
      "/sv/m4v-to-alac",
      "/sv/m4v-to-amr",
      "/sv/m4v-to-android-video",
      "/sv/m4v-to-avi",
      "/sv/m4v-to-flac",
      "/sv/m4v-to-flv",
      "/sv/m4v-to-ipad-video",
      "/sv/m4v-to-iphone-video",
      "/sv/m4v-to-kindle-video",
      "/sv/m4v-to-m4a",
      "/sv/m4v-to-mkv",
      "/sv/m4v-to-mobile-video",
      "/sv/m4v-to-mov",
      "/sv/m4v-to-mp3",
      "/sv/m4v-to-mp4",
      "/sv/m4v-to-ogg",
      "/sv/m4v-to-ogv",
      "/sv/m4v-to-psp-video",
      "/sv/m4v-to-wav",
      "/sv/m4v-to-webm",
      "/sv/m4v-to-wma",
      "/sv/m4v-to-wmv",
      "/sv/m4v-to-xbox-video",
    ],
    "/tr/video-converter": [
      "/tr/m4v-converter",
      "/tr/m4v-to-3gp",
      "/tr/m4v-to-aac",
      "/tr/m4v-to-aiff",
      "/tr/m4v-to-alac",
      "/tr/m4v-to-amr",
      "/tr/m4v-to-android-video",
      "/tr/m4v-to-avi",
      "/tr/m4v-to-flac",
      "/tr/m4v-to-flv",
      "/tr/m4v-to-ipad-video",
      "/tr/m4v-to-iphone-video",
      "/tr/m4v-to-kindle-video",
      "/tr/m4v-to-m4a",
      "/tr/m4v-to-mkv",
      "/tr/m4v-to-mobile-video",
      "/tr/m4v-to-mov",
      "/tr/m4v-to-mp3",
      "/tr/m4v-to-mp4",
      "/tr/m4v-to-ogg",
      "/tr/m4v-to-ogv",
      "/tr/m4v-to-psp-video",
      "/tr/m4v-to-wav",
      "/tr/m4v-to-webm",
      "/tr/m4v-to-wma",
      "/tr/m4v-to-wmv",
      "/tr/m4v-to-xbox-video",
    ],
    "/zh/video-converter": [
      "/zh/m4v-converter",
      "/zh/m4v-to-3gp",
      "/zh/m4v-to-aac",
      "/zh/m4v-to-aiff",
      "/zh/m4v-to-alac",
      "/zh/m4v-to-amr",
      "/zh/m4v-to-android-video",
      "/zh/m4v-to-avi",
      "/zh/m4v-to-flac",
      "/zh/m4v-to-flv",
      "/zh/m4v-to-ipad-video",
      "/zh/m4v-to-iphone-video",
      "/zh/m4v-to-kindle-video",
      "/zh/m4v-to-m4a",
      "/zh/m4v-to-mkv",
      "/zh/m4v-to-mobile-video",
      "/zh/m4v-to-mov",
      "/zh/m4v-to-mp3",
      "/zh/m4v-to-mp4",
      "/zh/m4v-to-ogg",
      "/zh/m4v-to-ogv",
      "/zh/m4v-to-psp-video",
      "/zh/m4v-to-wav",
      "/zh/m4v-to-webm",
      "/zh/m4v-to-wma",
      "/zh/m4v-to-wmv",
      "/zh/m4v-to-xbox-video",
    ],
  };

  const redirectUrl = Object.keys(redirects).find((targetPage) =>
    redirects[targetPage].includes(req.url)
  );

  if (redirectUrl) {
    res.writeHead(308, { Location: redirectUrl });
    res.end();
  } else {
    next();
  }
}
