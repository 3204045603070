import AzureUrlBuilder from "~/fc/utils/azure";
import { OLD_UNITS } from "../fc/Constants/old-units";

const Redirections = {
  "pdf-compressor": {
    redirect_url: "compress-pdf",
    internal_link: true,
  },
  "ebook-to-pdf": {
    redirect_url: "pdf-Converter",
    internal_link: true,
  },
  "/convert/gif-to-apng": {
    redirect_url: "gif-to-apng",
    internal_link: true,
  },
  "/convert/apng-to-gif": {
    redirect_url: "apng-to-gif",
    internal_link: true,
  },
};

export default function ({ route, redirect, app }) {
  const fullPath = route.fullPath;
  const id = route.params.id;
  const locale = app.$cookies.get('locale') || 'en'

  const azureUrlBuilder = new AzureUrlBuilder(locale);
  Redirections["/auth/forget"] = {
    redirect_url: azureUrlBuilder.FORGOT_PASSWORD_FLOW_URL,
    internal_link: false,
  };

  if (Redirections[fullPath]) {
    if (Redirections[fullPath].internal_link) {
      redirect(301, "/" + Redirections[fullPath].redirect_url);
    } else {
      redirect(301, Redirections[fullPath].redirect_url);
    }
  }

  if (OLD_UNITS.includes(id)) {
    redirect(301, "/unit/" + id);
  }
}
