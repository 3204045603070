const { AZURE_POLICIES } = require("../constants");

class AzureUrlBuilder {
  constructor(locale) {
    this.env = process.env.isStaging ? process.env.NODE_ENV + "." : "";
    this.tenantId = process.env.B2C_TENANT_ID;
    this.clientId = process.env.B2C_CLIENT_ID;
    this.baseUrl = `https://auth.${this.env}freeconvert.com/${this.tenantId}/oauth2/v2.0/authorize`;
    this.commonParams = `client_id=${this.clientId}&nonce=defaultNonce&scope=openid&response_type=code`;
    if (locale !== 'en') {
      this.commonParams += `&locale=${locale}`
    }
  }

  buildUrl(flow, policyName, extraParams = "") {
    const redirectUri =
      process.env.DOMAIN + "/auth/exchange-token/" + policyName;
    return `${this.baseUrl}?p=${flow}&${
      this.commonParams
    }&redirect_uri=${encodeURIComponent(redirectUri)}${extraParams}`;
  }

  get LOGIN_FLOW_URL() {
    return this.buildUrl("B2C_1A_SIGNIN", AZURE_POLICIES.signin.name);
  }

  get SIGNUP_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_SIGNUP",
      AZURE_POLICIES.signup.name,
      "&prompt=login"
    );
  }

  get FORGOT_PASSWORD_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_PASSWORD_RESET",
      AZURE_POLICIES.password_reset.name
    );
  }

  get CHANGE_PASSWORD_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_PASSWORD_CHANGE",
      AZURE_POLICIES.password_change.name
    );
  }

  get GOOGLE_SIGNUP_SIGNIN_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_GOOGLE_SIGNUP_SIGNIN",
      AZURE_POLICIES.google_signup_signin.name
    );
  }

  get DISCONNECT_GOOGLE_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_DISCONNECT_GOOGLE",
      AZURE_POLICIES.disconnect_google.name
    );
  }

  get FACEBOOK_SIGNUP_SIGNIN_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_FACEBOOK_SIGNUP_SIGNIN",
      AZURE_POLICIES.facebook_signup_signin.name
    );
  }

  get DISCONNECT_FACEBOOK_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_DISCONNECT_FACEBOOK",
      AZURE_POLICIES.disconnect_facebook.name
    );
  }

  get SET_PASSWORD_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_SET_PASSWORD",
      AZURE_POLICIES.set_password.name
    );
  }

  get SET_PASSWORD_FACEBOOK_FLOW_URL() {
    return this.buildUrl(
      "B2C_1A_SET_PASSWORD_Facebook",
      AZURE_POLICIES.set_password_facebook.name
    );
  }

  get LOGOUT_FLOW_URL() {
    const redirectUri = process.env.DOMAIN + "/auth/exchange-token/logout";
    return `https://auth.${this.env}freeconvert.com/${
      this.tenantId
    }/B2C_1A_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
      redirectUri
    )}`;
  }
}

// const fcEnv = process.env.isStaging ? process.env.NODE_ENV + "." : "";
// const azureUrlBuilder = new AzureUrlBuilder(fcEnv);
// module.exports = azureUrlBuilder;
export default AzureUrlBuilder;
