/**
 * We have similar statuses in fc-payment service too
 * Please make sure to update that service also when you update here
 */
export const PENDING_REMOTE_STATUSES = [
  "approval_pending",
  "incomplete",
  "past_due",
  "payer_action_required",
  "pending",
  "requires_confirmation",
  "requires_payment_method",
];

export const EVENT_BUS = {
  CANCEL_UPLOAD: "CANCEL_UPLOAD",
  RESTART_STUCK_JOB_WATCHER: "RESTART_STUCK_JOB_WATCHER",
};

export const DMCA_COPYRIGHTED_SOURCES = [
  "youtube.com",
  "googlevideo.com",
  "ytimg.com",
  "googleusercontent.com",
  "youtu.be",
  "netflix.com",
  "hulu.com",
  "primevideo.com",
  "disneyplus.com",
  "apple.com/tv",
  "hbomax.com",
  "vimeo.com",
  "dailymotion.com",
  "itunes.apple.com",
  "music.apple.com",
  "spotify.com",
  "music.amazon.com",
  "tidal.com",
  "deezer.com",
  "pandora.com",
  "soundcloud.com",
];

export const INTERRUPTED_JOBS_CHANNEL = "INTERRUPTED_JOBS_CHANNEL";
export const MAX_CONCURRENT_CONVERSIONS_DEFAULT = 5;
export const EXPIRY_TIME_FOR_FILE_DOWNLOAD = 8;
export const EXPIRY_TIME_FOR_RELOADED_FILE_DOWNLOAD = 2;

export const AZURE_POLICIES = {
  signin: {
    name: "signin",
    errorRedirectUrl: "/auth/login",
  },
  signup: {
    name: "signup",
    errorRedirectUrl: "/auth/signup",
  },
  password_reset: {
    name: "password_reset",
    errorRedirectUrl: "/auth/login",
  },
  password_change: {
    name: "password_change",
    errorRedirectUrl: "/account",
  },
  google_signup_signin: {
    name: "google_signup_signin",
    errorRedirectUrl: "/auth/login",
  },
  disconnect_google: {
    name: "disconnect_google",
    errorRedirectUrl: "/account",
  },
  facebook_signup_signin: {
    name: "facebook_signup_signin",
    errorRedirectUrl: "/auth/login",
  },
  disconnect_facebook: {
    name: "disconnect_facebook",
    errorRedirectUrl: "/account",
  },
  set_password: {
    name: "set_password",
    errorRedirectUrl: "/account",
  },
  set_password_facebook: {
    name: "set_password_facebook",
    errorRedirectUrl: "/account",
  },
  logout: {
    name: "logout",
    errorRedirectUrl: "/",
  },
};
