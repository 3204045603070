import { LOCALIZED_PRICING_ROUTES } from '~/config/subscription.config'
export default function ({ route, redirect, app }) {
    const { locale } = app.i18n; // Get the current locale
    const allowedPath = "/pricing"; // Allowed page for restricted locales

    // Check if the current locale is restricted and the route is invalid
    if (
        LOCALIZED_PRICING_ROUTES.includes(locale) &&
        route.path.startsWith(`/${locale}`) &&
        !route.path.startsWith(`/${locale}${allowedPath}`)
    ) {
        const newRoute = route.fullPath.replace(`/${locale}`, '') || '/';
        if (process.client) {
            // Redirect on the client side
            window.location.href = newRoute;
        } else {
            // Redirect on the server side
            redirect(301, newRoute);
        }
    }
}
